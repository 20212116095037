import React, { useState, useEffect } from "react";
import axios from "axios";
import SwipeableCards from "./SwipeableCards";
import "./VipMain.css";
import avatar from "../../assets/avatar.png";
import vipzero from "../../assets/vip-zero.png";
import {domain} from "../config"
// import FullWidthTabs from './tab'; // Uncomment if needed

function VipMain() {
  const [userData, setUserData] = useState({
    bets: {
      total: 0,
    },
    username: "",
    vipProgress: "",
    vipLevel: "VIP0",
    nextVipLevel: "",
    progressPercentage: 0,
  });

  const levels = [
    { minAmount: 1000, awarded: "VIP1" },
    { minAmount: 3000, awarded: "VIP2" },
    { minAmount: 10000, awarded: "VIP3" },
    { minAmount: 10000, awarded: "VIP4" },
    { minAmount: 10000, awarded: "VIP5" },
    { minAmount: 10000, awarded: "VIP6" },
    { minAmount: 10000, awarded: "VIP7" },
    { minAmount: 10000, awarded: "VIP8" },
    { minAmount: 10000, awarded: "VIP9" },
    { minAmount: 10000, awarded: "VIP10" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${domain}/user`, {
          withCredentials: true,
        });

        const { user, bets } = response.data;
        console.log("----->", response.data);

        let nextVipLevel = levels.find((level) => bets.total < level.minAmount);
        let remainingAmount = nextVipLevel
          ? nextVipLevel.minAmount - bets.total
          : 0;
        let progressPercentage = nextVipLevel
          ? (bets.total / nextVipLevel.minAmount) * 100
          : 100;

        setUserData({
          username: user.username,
          totalBets:bets.total,
          vipProgress:
            remainingAmount > 0
              ? `${remainingAmount} left to unlock ${nextVipLevel.awarded} level`
              : "Max VIP level achieved",
          nextVipLevel: nextVipLevel ? nextVipLevel.awarded : "Max VIP",
          progressPercentage,
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  const [lastAchievement, setLastAchievement] = useState(null);
  useEffect(() => {
    const fetchLastAchievement = async () => {
      try {
        const response = await axios.get(`${domain}/last-achievement`, {
          withCredentials: true,
        });

        setLastAchievement(response.data.lastAchievement);
      } catch (err) {
        console.error("Error fetching last achievement:", err);
      }
    };

    fetchLastAchievement();
  }, []);



  const getImageForAchievement = () => {
    if (!lastAchievement) return "assets/vip-zero.png";

    switch (lastAchievement) {
      case "VIP1":
        return "../../assets/vip1.png";
      case "VIP2":
        return "../../assets/vip2.png";
      case "VIP3":
        return "../../assets/vip3.png";
      case "VIP4":
        return "../../assets/vip4.png";
      case "VIP5":
        return "../../assets/vip5.png";
      case "VIP6":
        return "../../assets/vip6.png";
      case "VIP7":
        return "../../assets/vip7.png";
      case "VIP8":
        return "../../assets/vip8.png";
      case "VIP9":
        return "../../assets/vip9.png";
      case "VIP10":
        return "../../assets/vip10.png";
      default:
        return "../../assets/vip-zero.png";
    }
  };

  console.log("userdata:", userData)


  return (
    <div>
      <div className="topbox">
        <div className="top-left">
          <div className="image-box">
            <img src={avatar} alt="" />
          </div>
        </div>
        <div className="top-right">
          <div className="top-right-top">
            <div className="top-image-box">
            <img
                    src={getImageForAchievement()}
                    alt="Achievement"
                    width="30%"
                    height="80%"
                  />
            </div>
          </div>
          <div className="top-right-bottom" style={{ marginBottom: "35%" }}>
            {userData.username}
          </div>
        </div>
      </div>
      <div className="bottom-box">
        <div className="exp-box">
          <div className="exp">
            <div className="exp-content">
              <span className="exp-number">{userData.totalBets} EXP</span>
              <span className="exp-text">My experience</span>
            </div>
          </div>
          <div className="exp">
            <div className="exp-content">
              <span className="exp-number">
                <strong>12</strong> Days
              </span>
              <span className="exp-text">Payout time</span>
            </div>
          </div>
          <div className="progress-bar">
            <div
              className="progress"
              style={{ width: `${userData.progressPercentage}%` }}
            />
          </div>
        </div>
        <div className="notice-mid" style={{ marginTop: "-5%" }}>
          <div className="n-box">
            VIP level rewards are settled at 2:00 am on the 1st of every month
          </div>
        </div>
        <SwipeableCards />
      </div>
      {/* Uncomment if needed */}
      {/* <FullWidthTabs /> */}
      <br />
      <br />
      <br />
    </div>
  );
}

export default VipMain;