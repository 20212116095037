
// **************LocalHost******************

// export const domain = 'http://localhost:4000';
// export const wssdomain = 'ws://localhost:4000';


// **************Production******************

// export const domain = 'https://aviatorbet.online';
// export const wssdomain = 'wss://aviatorbet.online';

// export const domain = 'https://wealthwin.site';
// export const wssdomain = 'wss://wealthwin.site';

export const domain = 'https://demo3.anotechsolutions.cloud';
export const wssdomain = 'wss://demo3.anotechsolutions.cloud';